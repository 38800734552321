import React from "react";
import "./About.css";
import { Container, Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const About = () => (
    <Container>
    <Row>
      <Col xs="12" sm="3" lg="2" className="mb-4 text-center">
        <FontAwesomeIcon
          icon="parachute-box"
          size="10x"
          className="img-fluid"
        />
      </Col>
      <Col sm="9" lg="7" className="mb-4">
        <h1>Digital Product Designer</h1>
        <p>
          Product focused creative & UX / UI with an understanding of how
          business goals, users needs, and thoughtful design are brought
          together to create successful digital products.
        </p>
        <Col xs="12" sm="10" md="6" className="pl-sm-0">
          <a
            className="mt-auto btn btn-outline-primary btn-lg btn-block"
            href="https://www.linkedin.com/in/barrykarsh/"
          >
            Communicate
            <FontAwesomeIcon
              icon="chevron-circle-right"
              size="sm"
              className="ml-1"
            />
          </a>
        </Col>
      </Col>
    </Row>
  </Container>
);

export default About;
import React from "react";
import "./Footer.css";
import { Container, Row, Col } from "reactstrap";

const Footer = () => (
  <div>
    <Container className="footer mt-3">
      <Row>
        <Col>
          <p className="text-center">
            &copy;2019 Barry Karsh. All rights reserved.
          </p>
        </Col>
      </Row>
    </Container>
  </div>
);

export default Footer;

import React, { Component } from "react";
import Filters from "./Filters";
import ProjectSummary from "./ProjectSummary";
import EmptyState from "./EmptyState";
import { Container, Row, Col } from "reactstrap";
import "./ProjectsFiltered.css";
import data from "../Data.json";

class ProjectsFiltered extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projects: [],
      filtersAvailable: [],
      filteredProjects: [],
      filter: ""
    };
  }
  componentWillMount() {
    this.setState({
      projects: data.projects,
      filtersAvailable: data.tags,
      filteredProjects: this.state.projects,
      filter: "all"
    });
  }

  handleFiltering = event => {
    let rawFilterName = event.target.innerHTML;
    let filterNameStrLower = rawFilterName.toString().toLowerCase();

    this.setState({ filter: filterNameStrLower }, () => {
      let projects = this.state.projects;
      let filterSelected = this.state.filter;
      let projectsNewlyFiltered = [];

      if (filterSelected === "all" || filterSelected === "") {
        //nothing
      } else {
        //filtered state
        projectsNewlyFiltered = [];
        projects.forEach(item => {
          item.tags.forEach(tag => {
            if (tag.toString().toLowerCase() === filterSelected) {
              projectsNewlyFiltered.push(item);
            }
          });
        });
        this.setState({ filteredProjects: projectsNewlyFiltered }, () => {
          return;
        });
      }
    });
  };

  getNumberOfProjectsForEachFilter() {
    //let filtersAvailable = this.state.filtersAvailable;
  }

  render() {
    let projects = this.state.projects;
    let filteredProjects = this.state.filteredProjects;
    let filterSelected = this.state.filter;
    let filtersAvailable = this.state.filtersAvailable;
    if (filterSelected === "all") {
      //inital state
      filteredProjects = projects;
    }

    return (
      <div>
        {this.getNumberOfProjectsForEachFilter()}
        <Col>
          <div className="filters">
            {filtersAvailable.map(filter => (
              <Filters
                name={filter}
                clickFunc={this.handleFiltering}
                key={filter}
              />
            ))}
          </div>
        </Col>
        <Container>
          <Row className="projects-filtered">
            {filteredProjects.length > 0 ? (
              filteredProjects.map(project => (
                <ProjectSummary
                  {...project}
                  key={project.id}
                  handleToUpdate={this.handleToUpdate}
                />
              ))
            ) : (
              <EmptyState filter={filterSelected} />
            )}
          </Row>
        </Container>
      </div>
    );
  }
}

export default ProjectsFiltered;

import React from "react";
import PropTypes from "prop-types";
import "./Section.css";
import Work from "./Work.js";
import About from "./About.js";

const COMP_TO_RENDER = {
  work: <Work item="work" />,
  about: <About item="about" />
};

const Section = props => (
  <div className={`section theme-${props.bgcolor}`}>
    {COMP_TO_RENDER[props.item]}
  </div>
);

Section.propTypes = {
  item: PropTypes.string
};

export default Section;

import React, { Component } from "react";
import { Router, Route, Switch } from "react-router-dom";
import createBrowserHistory from "history/createBrowserHistory";
//Font Awesone 5
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faFrown,
  faParachuteBox,
  faHammer,
  faChevronCircleRight,
  faArrowCircleRight
} from "@fortawesome/free-solid-svg-icons";
// import logo from './logo.svg';
import "./App.css";
import NavMain from "./Components/NavMain.js";
import Footer from "./Components/Footer";
import Home from "./Routes/Home";
import ProjectsDisplay from "./Routes/ProjectsDisplay";
//Font Awesome Global Library
library.add(
  faFrown,
  faParachuteBox,
  faHammer,
  faChevronCircleRight,
  faArrowCircleRight
);

const history = createBrowserHistory();

class App extends Component {
  ScrollToTop = () => {
    window.scrollTo(0, 0);
    return null;
  };
  render() {
    return (
      <Router history={history}>
        <div className="App">
          <Route component={this.ScrollToTop} />
          <NavMain />
          <Switch>
            <Route exact path="/" component={Home} />
            {/*<Route path="/project" component={ProjectDisplay} />*/}
            <Route path="/:value" component={ProjectsDisplay} />
            />
          </Switch>
          <Footer />
        </div>
      </Router>
    );
  }
}

export default App;

import React, { Component } from "react";
import "./ProjectDisplay.css";
import ProjectDetails from "../Components/ProjectDetails";
import ProjectsFiltered from "../Components/ProjectsFiltered";
import Data from "../Data.json";
import { Row, Container, Col } from "reactstrap";
//import Tags from "./Tags";

class ProjectsDisplay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projectURL: "",
      projects: [],
      project: []
    };
  }

  componentWillMount() {
    this.setState({
      projectURL: this.props.match.params.value,
      projects: Data.projects
    });
  }
  componentDidMount() {
    this.handleGetProject(this.state.projectURL);
  }

  handleGetProject = projectURL => {
    var projectCurrent = this.state.projects.filter(function(project) {
      return project.projectURL === projectURL;
    });
    this.setState({
      project: projectCurrent
    });
  };
  handleToUpdate = someArg => {
    //makes state update when called from projects grid at bottom
    console.log("display");
  };
  render() {
    let project = this.state.project;
    let bgColors = Data.themeColors;

    return (
      <div>
        <div className="section theme-gray">
          <Container>
            <Row>
              <Col xs="12">
                {project.map(item => (
                  <div key={item.id}>
                    <h1>{item.projectName}</h1>
                    <h3>{item.client}</h3>
                  </div>
                ))}
              </Col>
            </Row>
          </Container>
        </div>
        {project.map((item, index) => (
          <ProjectDetails {...item} key={index} bgColors={bgColors} />
        ))}
        <div className="section theme-gray">
          <Container>
            <Row className="mt-3 more-work">
              <Col>
                <h1 className="mb-3">More Work</h1>
              </Col>
              <ProjectsFiltered handleToUpdate={this.handleToUpdate} />
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}
export default ProjectsDisplay;

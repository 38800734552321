import React, { Component } from "react";
import Section from "../Components/Section.js";
import Data from "../Data.json";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      themeColors: []
    };
  }

  componentWillMount() {
    this.setState({
      themeColors: Data.themeColors
    });
  }

  render() {
    return (
      <div className="Home">
        <Section item="about" bgcolor={this.state.themeColors[0]} />
        <Section item="work" bgcolor={this.state.themeColors[1]} />
      </div>
    );
  }
}

export default Home;

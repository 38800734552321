import React from "react";
import "./Filters.css";
import { Button } from "reactstrap";

const Filters = props => (
  <Button outline color="primary" key={props.name} onClick={props.clickFunc}>
    {props.name}
  </Button>
);

export default Filters;

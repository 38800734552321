// @flow
import React from "react";
import "./ProjectDetails.css";
import { Row, Container, Col, Card, CardBody } from "reactstrap";
import ReactPlayer from "react-player";

const ProjectDetails = props => (
  <div className="project-details">
    {/*<div className={`section section-separator theme-${props.bgColors[4]}`} />*/}
    {props.details.map((item, index) => (
      <div className={`section theme-${props.bgColors[index]}`} key={index}>
        <Container>
          <Row>
            <Col xs="12">
              <h1 className="section-index">0{index + 1}</h1>
              <h1 className="mb-3">{item.title}</h1>
            </Col>
          </Row>
          <Row>
            {item.content && (
              <Col xs="12">
                <p>{item.content}</p>
              </Col>
            )}
            {item.images && (
              <div className="col-12 d-md-flex scroll-x">
                {item.images.map((image, index) => (
                  <Col md="5" key={index}>
                    <Card>
                      <CardBody className="p-1">
                        <img
                          src={require(`../content/img/${image}`)}
                          alt="product"
                          className="img-fluid"
                        />
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </div>
            )}
            {item.videos && (
              <div className="col-12 d-md-flex scroll-x">
                {item.videos.map((video, index) => (
                  <Col md="5" key={index}>
                    <Card>
                      <CardBody>
                        <div className="player-wrapper">
                          <ReactPlayer
                            className="react-player"
                            controls
                            url={require(`../content/vid/${video}`)}
                            width="100%"
                            height="100%"
                          />
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </div>
            )}
          </Row>
        </Container>
      </div>
    ))}
  </div>
);

export default ProjectDetails;

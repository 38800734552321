import React, { Component } from "react";
import "./Work.css";
import { Container, Row, Col } from "reactstrap";
import ProjectsFiltered from "./ProjectsFiltered";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class Work extends Component {
  render() {
    return (
      <Container>
        <Row>
          <Col xs="12" sm="3" className="mb-4 text-center">
            <FontAwesomeIcon icon="hammer" size="10x" className="img-fluid" />
          </Col>
          <Col xs="12" sm="9" className="mb-4">
            <h1>Work</h1>
            <p>
              <strong>You:</strong> Hmm...Is this guy right for my project?
            </p>
            <p>
              <strong>Me:</strong> Could be! Select an item below to filter the
              projects list and find out :-)
              <br />
              <small className="gray">
                *More projects available upon request.
              </small>
            </p>
          </Col>
        </Row>
        <Row className="mt-3">
          <ProjectsFiltered />
        </Row>
      </Container>
    );
  }
}
export default Work;

import React from "react";
import { Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const EmptyState = props => {
  return (
    <Col className="text-center">
      {props.filter === "visual design" ? (
        <div>
          <h2>It's in my other pants</h2>
          <p>
            As I work on my new site, my visual design work can be see at my
            other website.
          </p>
          <a
            href="https://personal-2015-b4007.firebaseapp.com"
            className="btn btn-outline-primary btn-lg"
          >
            Visual Design{" "}
            <FontAwesomeIcon
              icon="chevron-circle-right"
              className="img-fluid"
            />
          </a>
        </div>
      ) : (
        <div>
          <FontAwesomeIcon icon="frown" size="6x" />
          <h2>And now, some intentionally bad UX</h2>
          So sorry. You're just a bad chooser :-)
          <br />
          But actually...this will be coming soon as I complete my website.
        </div>
      )}
    </Col>
  );
};
export default EmptyState;

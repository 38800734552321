import React from "react";
import "./Tags.css";

const Tags = props => (
  <span>
    {props.tags.map((item, key) => {
      return props.numOfTags > key + 1 ? (
        <span className="tag" key={key}>
          {item},&nbsp;
        </span>
      ) : (
        <span className="tag" key={key}>
          {item}
        </span>
      );
    })}
  </span>
);

export default Tags;

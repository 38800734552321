import React, { Component } from "react";
import "./Project.css";
import { Link } from "react-router-dom";
import Tags from "./Tags";
import { Col, Card, CardText, CardTitle, CardSubtitle } from "reactstrap";

class ProjectSummary extends Component {
  render() {
    var handleToUpdate = this.props.handleToUpdate;
    return (
      <Col xs="12" sm="6" md="4" className="d-flex align-items-stretch">
        <Card body className="d-flex flex-column">
          {/*<CardImg
        top
        width="100%"
        src="https://placeholdit.imgix.net/~text?txtsize=33&txt=318%C3%97180&w=318&h=180"
        alt="Card image cap"
      />*/}

          <CardTitle tag="h3">{this.props.client}</CardTitle>
          <CardSubtitle className="mb-2">{this.props.projectName}</CardSubtitle>
          <CardText>
            <Tags tags={this.props.tags} numOfTags={this.props.tags.length} />
          </CardText>

          <Link
            to={`/${this.props.projectURL}`}
            onClick={() => handleToUpdate("someVar")}
            className="mt-auto btn btn-primary btn-project-grid"
          >
            More
          </Link>
        </Card>
      </Col>
    );
  }
}
export default ProjectSummary;
